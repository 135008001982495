import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, computed, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, first, map, switchMap } from 'rxjs/operators';

import { AppService } from '@logic-suite/shared/app.service';
import { LanguageService } from '@logic-suite/shared/i18n';
import { objToString } from '@logic-suite/shared/utils';

import { toSignal } from '@angular/core/rxjs-interop';
import { AccessService, Application } from '../../shared/access';
import { AppPrimaryDialogComponent } from './app-primary-dialog.component';

@Component({
  selector: 'app-app-list',
  templateUrl: './app-list.component.html',
  styleUrls: ['./app-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppListComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  applications$ = new BehaviorSubject<Application[]>([]);
  applications = toSignal(this.applications$);

  available = computed(() => this.applications()?.reduce((acc, curr) => (curr.hasAccess ? (acc += 1) : acc), 0) ?? 0);
  primaryApp = computed(() => {
    const primary = this.app.getItem('primaryApp', -1);
    return this.applications()?.find(a => a.applicationID === primary);
  });
  isAdministrator = signal(false);

  constructor(
    private access: AccessService,
    private app: AppService,
    private lang: LanguageService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.app.setTitle('');
    this.app.setApplicationID(0);
    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        if (params.forceRoot) {
          // User explicitly asked for root as homepage. Remove previous selection.
          this.app.removeItem('primaryApp');
        }
      }),
    );
    this.subscriptions.push(
      combineLatest([this.access.getCurrentUserRole(), this.access.getApplicationList()])
        .pipe(
          distinctUntilChanged(
            ([oldUT, oldLst], [newUT, newLst]) =>
              oldUT.roleID === newUT.roleID &&
              objToString(oldLst.map(l => l.hasAccess)) === objToString(newLst.map(l => l.hasAccess)),
          ),
        )
        .subscribe(([userType, lst]) => {
          const available = lst.filter(l => l.hasAccess);
          this.isAdministrator.set(userType.roleID <= 1);
          const primary =
            available.length === 1 && userType.roleID > 0
              ? available[0].applicationID
              : this.app.getItem('primaryApp', '0');
          if (primary) {
            // Primary application is chosen. Redirect if user has access
            this.gotoApp(primary).subscribe(val => {
              if (!val) {
                // Application was not found. Remove and force reselection.
                this.app.removeItem('primaryApp');
              }
            });
          }

          // Admin sees all applications, all others see only available
          this.applications$.next(userType.roleID === 0 ? lst : available);
        }),
    );

    this.subscriptions.push(
      this.lang.onLangChange
        .pipe(
          switchMap((lng: LangChangeEvent) =>
            combineLatest([this.lang.loadTranslations('app'), this.applications$.pipe(filter(apps => !!apps))]),
          ),
        )
        .subscribe(([lng, apps]) => {
          apps.forEach(a => (a.description = (lng as any)[a?.applicationName ?? '']));
        }),
    );
  }

  getLogo(application: Application) {
    let url = application?.applicationUrl;
    url = url ? url.split('/').join('') : 'suite';
    return `/assets/${url}_logo.svg`;
  }

  gotoApp(applicationID: number): Observable<boolean> {
    return this.access.getApplicationList().pipe(
      first(),
      map(apps => {
        const app = apps.find(a => a.applicationID === applicationID);
        if (app) {
          this.router.navigate([app.applicationUrl.split('/').join('')]);
          return true;
        }
        return false;
      }),
    );
  }

  makePrimaryApp($event: MouseEvent, applicationID: number) {
    $event.stopPropagation();
    $event.preventDefault();
    const app = this.applications()?.find(a => a.applicationID === applicationID);
    this.dialog
      .open(AppPrimaryDialogComponent, { panelClass: 'draggable-dialog', data: app?.applicationName })
      .afterClosed()
      .subscribe(val => {
        if (val) {
          this.app.setItem('primaryApp', applicationID);
          this.gotoApp(applicationID).subscribe();
        }
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
