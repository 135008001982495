import { DataTypeDefinition, GridOptions } from '@ag-grid-community/core';
import { dateToMillis, getUserLocaleDateFormat, millisToDate } from '@logic-suite/shared/utils';
import { format, isValid } from 'date-fns';

export const DEFAULT_GRID_OPTIONS: GridOptions = {
  dataTypeDefinitions: {
    /**
     * Our dates are stored as milliseconds, but we want to display them as dd.MM.yyyy
     * This will fix the display and parsing of dates in the grid, but will not affect the
     * date format presented in the editor. I do not know how to change the editor format.
     */
    millis: {
      baseDataType: 'dateString',
      extendsDataType: 'dateString',
      // Validate or convert the value to a number
      valueParser: (params: any) =>
        typeof params.newValue === 'number' ? params.newValue : dateToMillis(params.newValue),
      // Convert to string for presentation
      valueFormatter: (params: any) =>
        params.value == null ? '' : format(millisToDate(params.value), getUserLocaleDateFormat()),
      // Convert to date for editing
      dateParser: (value: any) => (value == null ? null : millisToDate(value)),
      // Convert to millis for saving
      dateFormatter: (value: Date) => (value == null ? '' : dateToMillis(value)),
      // Make sure that values of this type are numbers and can convert to a valid date
      // ⚠️BEWARE⚠️: This will set the date editor for any numeric value, unless the
      // `cellDataType` is explicitly set to `number`. But without this dataTypeMatcher,
      // the valueParser will reject numeric values.
      dataTypeMatcher: value => typeof value === 'number' && isValid(millisToDate(value)),
    } as DataTypeDefinition,
  },
  // This is a hack to make the grid stop editing when the input loses focus
  // onCellEditingStarted: params => {
  //   const input =
  //     (params.event?.target as HTMLElement)?.querySelector('input') ??
  //     (
  //       params.eventPath?.find(e => (e as HTMLElement).classList.contains('ag-body-viewport')) as HTMLElement
  //     )?.querySelector('input');
  //   if (input) {
  //     input.onblur = () => {
  //       params.api.stopEditing();
  //     };
  //   }
  // },
};
