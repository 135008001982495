@if (isAuthenticated) {
  <header>
    <mat-toolbar>
      <section class="home">
        <a [routerLink]="['/']" [title]="'Go to application overview' | translate">
          <img src="/assets/noova_logo.svg" class="logo screen-only" alt="application logo" />
          <img src="/assets/noova_logo_inv.svg" class="logo print-only" alt="application logo" />
        </a>
        @if (currentCustomer().customerID) {
          <a [routerLink]="['/select-customer']" [queryParams]="{ stateData: returnState() }">
            <h1>
              {{ currentCustomer().customerID }}
              @if (companyName()) {
                - {{ companyName() }}
              }
            </h1>
          </a>
        }
      </section>

      @if (!isRoot()) {
        <app-app-menu></app-app-menu>
      }
      <menu class="screen-only">
        <a mat-icon-button #menuTrigger (click)="toggleMenu()" title="{{ 'Menu' | translate }}">
          <mat-icon>menu</mat-icon>
        </a>
      </menu>
    </mat-toolbar>
  </header>

  @if (menuVisible()) {
    <app-menu @dropdown [forceEnv]="env"> </app-menu>
  }
}

<lib-loader></lib-loader>

<section>
  <router-outlet></router-outlet>
</section>
